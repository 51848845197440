body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.height-100 {
  height: 100%;
}

div.flex-scroll-container {
  height: 100%;
  flex-wrap: nowrap;
  overflow: hidden;
}

.overflow-auto {
  height: 100%;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
